import Location from "./Location";

import { Paper, Typography, Grid, Button } from "@mui/material";

const Locations = ({ locations, setState }) => {
  const addLocationField = () => {
    setState((draft) => {
      draft.locations.push({
        name: "",
        contact: {
          name: {
            first: "",
            last: "",
          },
          email: "",
          phoneNumber: "",
        },
        address: {
          country: "",
          state: "",
          city: "",
          zip_code: "",
          street: "",
        },
      });
    });
  };

  return (
    <Paper sx={{ padding: 5 }} elevation={5}>
      <Typography variant="h4" sx={{ marginBottom: 4 }}>
        Locations
      </Typography>
      <Grid container direction="column" spacing={4}>
        {locations.map((location, index) => (
          <Grid item key={index}>
            <Typography>Location {index + 1}</Typography>
            <Location setState={setState} location={location} index={index} />
          </Grid>
        ))}
      </Grid>
      <Button
        onClick={addLocationField}
        variant="contained"
        sx={{ marginTop: 4 }}
      >
        Add Another Location
      </Button>
    </Paper>
  );
};

export default Locations;
