import {
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import { collection } from "firebase/firestore";

import { db } from "../../firebase";

import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  Container,
  TableContainer,
  TableCell,
  Typography,
  Grid,
} from "@mui/material";

const Stats = () => {
  const [studies, studiesLoading] = useCollectionData(
    collection(db, `Studies`)
  );

  const [cancers, cancersLoading] = useCollection(collection(db, "Cancers"));

  const [sponsors, sponsorsLoading] = useCollection(collection(db, "Sponsors"));

  const getNumberOfStudiesBySponsor = (sponsor) => {
    const filteredStudies = studies.filter(
      (study) => study.sponsor === sponsor
    );
    return filteredStudies.length;
  };

  return (
    <Container>
      <Grid container spacing={5}>
        <Grid item xs={4}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 0, whiteSpace: "nowrap" }}>
                    <Typography>NCT #'s</Typography>
                  </TableCell>
                  <TableCell sx={{ width: 0, whiteSpace: "nowrap" }}>
                    <Typography>Cancer Types</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!studiesLoading &&
                  studies.map((study, index) => (
                    <TableRow key={index}>
                      <TableCell
                        sx={{
                          width: 0,
                          whiteSpace: "nowrap",
                          verticalAlign: "top",
                        }}
                      >
                        <Typography>{study.nctNumber}</Typography>
                      </TableCell>
                      <TableCell sx={{ width: 0, whiteSpace: "nowrap" }}>
                        {study.cancers.map((cancer) => (
                          <Typography>{cancer}</Typography>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={8}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography>Cancer Types</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>Stats</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography># Of Studies By Sponsor</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ verticalAlign: "top" }}>
                    {!cancersLoading &&
                      cancers.docs.map((cancer, index) => (
                        <Typography key={index}>{cancer.id}</Typography>
                      ))}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: "top" }}>
                    <Typography>
                      # of Studies: {!studiesLoading && studies.length}
                    </Typography>
                    <Typography>
                      # of Sponsors: {!sponsorsLoading && sponsors.docs.length}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ verticalAlign: "top" }}>
                    {!sponsorsLoading &&
                      !studiesLoading &&
                      sponsors.docs.map((sponsor, index) => (
                        <Typography key={index}>
                          {sponsor.id}:{" "}
                          {getNumberOfStudiesBySponsor(sponsor.id)}
                        </Typography>
                      ))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Stats;
