import { createContext, useReducer } from "react";

import { firebaseAuth } from "../reducers/authReducer";

// export const
export const AuthContext = createContext();

const initialState = { user: null, error: null };

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(firebaseAuth, initialState);
  const value = { state, dispatch };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
