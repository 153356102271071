import { useContext, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import SideNavMenu from "./SideNavMenu";
import { AuthContext } from "../../contexts/AuthContext";

import logo from "../../images/xcancer-logo.png";

import {
  Grid,
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";

const Header = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => setAnchorEl(null);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      {state?.user?.lastName}
      <AppBar position="sticky" sx={{ background: "#dcdcdc" }}>
        <Toolbar disableGutters>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={2}>
              <Link to="/">
                <img
                  src={logo}
                  style={{ marginTop: 10, marginLeft: 10, maxHeight: "6vh" }}
                  alt="XCancer Logo"
                />
              </Link>
            </Grid>
            {searchParams && (
              <Grid
                item
                xs={9}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Typography variant="h2" sx={{ color: "#006ba8" }}>
                  {searchParams.get("cancer")}
                </Typography>
              </Grid>
            )}
            <Grid item xs={1}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={6}>
                  <IconButton onClick={handleAvatarClick}>
                    <Avatar sx={{ maxHeight: "10vh", maxWidth: "10vh" }} />
                  </IconButton>
                </Grid>
                <Grid item xs={6}>
                  <SideNavMenu />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {state.user ? (
          <>
            <MenuItem>Profile</MenuItem>
            <Divider />
            <MenuItem>Log Out</MenuItem>
          </>
        ) : (
          <MenuItem onClick={() => navigate("/login")}>Log In</MenuItem>
        )}
      </Menu>
    </>
  );
};

export default Header;
