import TopBanner from "./TopBanner";
import Search from "./Search";
// import BottomBannerAd from "../../images/home_banner_bottom_one.jpg";

import { Grid, Typography } from "@mui/material";

const Home = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item xs={12}>
        <TopBanner />
      </Grid>
      <Grid item xs={12}>
        <Search />
      </Grid>
      <Grid item>
        <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
          <Grid
            item
            xs={9}
            sx={{
              background: "grey",
              height: 180,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>Sponsor Ad Here</Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid item sx={{ display: "flex", justifyContent: "center" }}>
        <img
          src={BottomBannerAd}
          alt="Banner"
          style={{ overflow: "hidden", width: "90vw" }}
        />
      </Grid> */}
    </Grid>
  );
};

export default Home;
