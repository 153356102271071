import { Typography } from "@mui/material";

import image from "../../images/home_banner_top_two.png";

const TopBanner = () => {
  return (
    <>
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          height: "28vh",
          width: "100vw",
          zIndex: 2,
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h3" sx={{ color: "#232264" }}>
          <i>
            <b>TheranosticTrials</b>:
          </i>{" "}
          Bringing Radiopharmaceutical Clinical Trials to your Fingertips
        </Typography>
      </div>

      <img
        src={image}
        alt="Banner"
        height="28vh"
        overflow="hidden"
        width="100%"
        style={{
          height: "28vh",
          objectFit: "cover",
          objectPosition: "20% 30%",
        }}
      />
    </>
  );
};

export default TopBanner;
