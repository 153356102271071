import { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";

import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
} from "@mui/material";

const Profile = () => {
  const [logo, setLogo] = useState();
  const params = useParams();

  const getSponsor = useCallback(() => {
    getDoc(doc(db, `Sponsors/${params.sponsor}`))
      .then((snapshot) => {
        if (!snapshot.exists()) return console.log("No data available");

        setLogo(snapshot.data().logo);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [params.sponsor]);

  useEffect(() => getSponsor(), [getSponsor]);

  return (
    <Container maxWidth="sm" sx={{ marginTop: 4 }}>
      <form>
        <Grid container spacing={4} direction="column">
          <Grid item>
            <Paper sx={{ padding: 5 }} elevation={5}>
              <Grid container sx={{ alignItems: "center" }} spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <img src={logo} alt={params.sponsor} />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Sponsor Name" required />
                </Grid>
                <Grid item xs={12}>
                  <TextField fullWidth label="Site Link" required />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Typography variant="h5">Primary Contact</Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField fullWidth label="First Name" required />
                </Grid>
                <Grid item xs={6}>
                  <TextField fullWidth label="Last Name" required />
                </Grid>
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <TextField fullWidth label="Title" required />
                </Grid>
                <Grid item xs={6}>
                  <TextField fullWidth label="Email" required />
                </Grid>
                <Grid item xs={6}>
                  <TextField fullWidth label="Phone Number" required />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button variant="contained">Upload Logo</Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item>
            <Button fullWidth variant="contained">
              <Typography>Submit</Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default Profile;
