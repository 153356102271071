import { useState } from "react";

import { setDoc, doc } from "firebase/firestore";

import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage, db } from "../../../../firebase";

import {
  Container,
  Grid,
  Paper,
  Typography,
  Button,
  TextField,
  Alert,
} from "@mui/material";

const NewSponsor = () => {
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState(null);
  const [sponsor, setSponsor] = useState("");
  const [siteLink, setSiteLink] = useState("");
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (file) {
      const sotrageRef = ref(storage, `sponsors/${sponsor}/logo/${file.name}`);
      const uploadTask = uploadBytesResumable(sotrageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const prog = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(prog);
        },
        (error) => setErrors([...errors, error.message]),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setProgress(0);
            try {
              setDoc(doc(db, `Sponsors`, sponsor), {
                logo: downloadURL,
                siteLink,
              });
              setSuccess(true);
              setErrors([]);
            } catch (e) {
              setErrors([...errors, e.message]);
            }
          });
        }
      );

      setFile(null);
      setSponsor("");
      setSiteLink("");
    } else {
      try {
        setDoc(doc(db, `Sponsors`, sponsor), { siteLink });
        setSuccess(true);
        setErrors([]);
      } catch (e) {
        setErrors([...errors, e.message]);
      }
    }
  };

  return (
    <>
      {errors.map((error, index) => (
        <Alert severity="error" key={index}>
          {error}
        </Alert>
      ))}
      {success && <Alert severity="success">Sponsor added successfully</Alert>}
      <Container maxWidth="sm" sx={{ marginTop: 4 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4} direction="column">
            <Grid item xs={12}>
              <Paper sx={{ padding: 5 }} elevation={5}>
                <Grid
                  container
                  sx={{ alignItems: "center" }}
                  direction="column"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Sponsor Name"
                      value={sponsor}
                      required
                      onChange={(e) => setSponsor(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Site Link"
                      value={siteLink}
                      onChange={(e) => setSiteLink(e.target.value)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <label htmlFor="contained-button-file">
                      <input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => setFile(e.target.files[0])}
                      />
                      <Button variant="contained" component="span">
                        Upload Logo
                      </Button>
                    </label>
                    {file && (
                      <Typography sx={{ marginLeft: 2 }} variant="h6">
                        {file.name}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                disabled={progress > 0}
                fullWidth
                variant="contained"
              >
                <Typography>Submit</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default NewSponsor;
