import { Routes, Route } from "react-router-dom";

import Home from "../Home";
import Layout from "./Layout";
import Studies from "../Studies";
import StudyDetails from "../Studies/Details/index";
import NewStudy from "../Forms/Study/NewStudy";
import EditStudy from "../Forms/Study/EditStudy";
import NewCancer from "../Forms/Cancer/NewCancer";
import NewSponsor from "../Forms/Sponsor/NewSponsor";
import Cancers from "../Cancers";
import Login from "../Auth/Login";
import Signup from "../Auth/Signup";
import Patient from "../About/Patient";
import Physician from "../About/Physician";
import Dashboard from "../Sponsor/Dashboard";
import SponsorStudies from "../Sponsor/Studies";
import Profile from "../Sponsor/Profile";
import HealthDisparities from "../About/HealthDisparities";
import Survey from "../About/Survey";
import Stats from "./Stats";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { AuthProvider } from "../../contexts/AuthContext";
import Pharma from "../About/Pharma";
import UpdateStudies from "./UpdateStudies";

library.add(fas);

const App = () => {
  return (
    <AuthProvider>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/updateStudies" element={<UpdateStudies />} />
          <Route path="/about">
            <Route path="patient" element={<Patient />} />
            <Route path="physician" element={<Physician />} />
            <Route path="pharma" element={<Pharma />} />
            <Route path="health_disparities" element={<HealthDisparities />} />
            <Route path="health_disparities/survey" element={<Survey />} />
          </Route>
          {/* Study Routes */}
          <Route path="/studies">
            <Route path="/studies" element={<Studies />} />
            <Route path=":study_id" element={<StudyDetails />} />
            <Route path="new" element={<NewStudy />} />
            <Route path=":study_id/edit" element={<EditStudy />} />
          </Route>
          {/* Cancer Routes */}
          <Route path="/cancers">
            <Route path="/cancers" element={<Cancers />} />
            <Route path="new" element={<NewCancer />} />
          </Route>
          {/* Sponsor Routes */}
          <Route path="/sponsors">
            <Route path=":sponsor" element={<Dashboard />} />
            <Route path=":sponsor/profile" element={<Profile />} />
            <Route path=":sponsor/studies" element={<SponsorStudies />} />
            <Route
              path=":sponsor/studies/:study_id/edit"
              element={<EditStudy />}
            />
            <Route path="new" element={<NewSponsor />} />
          </Route>
        </Routes>
      </Layout>
    </AuthProvider>
  );
};

export default App;
