import { setDoc, doc, getDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";

import { db, auth } from "../firebase";

export const firebaseAuth = (state, { type, payload }) => {
  const createUser = async () => {
    await createUserWithEmailAndPassword(auth, payload.email, payload.password);

    const userRef = doc(db, `Users`, payload.email);

    await setDoc(userRef, {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      sponsor: payload.sponsor,
    });

    const docRef = await getDoc(userRef);

    return docRef.data();
  };

  switch (type) {
    case "SIGNUP":
      try {
        createUser().then((user) => {
          return { ...state, user };
        });
      } catch ({ code, message }) {
        return { ...state, error: [`${code}: ${message}`] };
      }
      break;
    case "LOGIN":
      return { ...state, user: payload };
    case "LOGOUT":
      return { ...state, user: null };

    default:
      return state;
  }
};
