import { Paper, Typography, Grid, TextField } from "@mui/material";

const EligibilityCriteria = ({ setState, eligibility }) => {
  return (
    <Paper sx={{ padding: 5 }} elevation={5}>
      <Typography variant="h4" sx={{ marginBottom: 4 }}>
        Eligibility Criteria
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            minRows={5}
            name="eligibility"
            value={eligibility}
            onChange={(e) =>
              setState((draft) => {
                draft.eligibility = e.target.value;
              })
            }
            label="Eligibility"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EligibilityCriteria;
