import { Grid, TextField, Typography } from "@mui/material";

const Location = ({ setState, location, index }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          value={location.name}
          onChange={(e) =>
            setState((draft) => {
              draft.locations[index].name = e.target.value;
            })
          }
          label="Name"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          value={location.address.country}
          onChange={(e) =>
            setState((draft) => {
              draft.locations[index].address.country = e.target.value;
            })
          }
          label="Country"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          value={location.address.state}
          onChange={(e) =>
            setState((draft) => {
              draft.locations[index].address.state = e.target.value;
            })
          }
          label="State"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          value={location.address.city}
          onChange={(e) =>
            setState((draft) => {
              draft.locations[index].address.city = e.target.value;
            })
          }
          label="City"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>Contact</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={location.contact.name.first}
          onChange={(e) =>
            setState((draft) => {
              draft.locations[index].contact.name.first = e.target.value;
            })
          }
          label="First Name"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={location.contact.name.last}
          onChange={(e) =>
            setState((draft) => {
              draft.locations[index].contact.name.last = e.target.value;
            })
          }
          label="Last Name"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={location.contact.email}
          onChange={(e) =>
            setState((draft) => {
              draft.locations[index].contact.email = e.target.value;
            })
          }
          label="Email"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          value={location.contact.phoneNumber}
          onChange={(e) =>
            setState((draft) => {
              draft.locations[index].contact.phoneNumber = e.target.value;
            })
          }
          label="Phone Number"
        />
      </Grid>
    </Grid>
  );
};

export default Location;
