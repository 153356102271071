import { useEffect, useState, useCallback } from "react";

import { db } from "../../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";

import Study from "./Study";

import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const StudiesContainer = ({ sponsor }) => {
  const [studies, setStudies] = useState([]);

  const getData = useCallback(async () => {
    const q = query(collection(db, `Studies`), where("sponsor", "==", sponsor));

    const studiesCollection = await getDocs(q);
    const studyDocs = [];

    studiesCollection.forEach((study) => studyDocs.push(study.data()));
    setStudies(studyDocs);
  }, [sponsor]);

  useEffect(() => getData(), [getData]);

  return (
    <Table size="small">
      <TableHead>
        <TableRow sx={{ backgroundColor: "#006ba8" }}>
          <TableCell sx={{ width: 0 }}>
            <Typography fontSize={20} color="white">
              NCT #
            </Typography>
          </TableCell>
          <TableCell>
            <Typography fontSize={20} color="white">
              Trial Name
            </Typography>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      {studies?.map((study, index) => (
        <Study study={study} sponsor={sponsor} key={index} />
      ))}
    </Table>
  );
};

export default StudiesContainer;
