import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDocumentData } from "react-firebase-hooks/firestore";

import { db } from "../../firebase";
import { doc } from "firebase/firestore";

import {
  TableCell,
  TableRow,
  Typography,
  CircularProgress,
} from "@mui/material";

const Study = ({ study }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const cancer = searchParams.get("cancer");

  const [background, setBackground] = useState("");
  const [sponsor, loading] = useDocumentData(
    doc(db, `Sponsors/${study.sponsor}`)
  );

  const handleClick = () => {
    navigate(`/studies/${study.nctNumber}`, { state: study });
  };

  const formattedURL = sponsor?.siteLink.includes("http")
    ? sponsor?.siteLink
    : `https://${sponsor?.siteLink}`;

  return (
    <TableRow
      key={study.nctNumber}
      sx={{ justifyContent: "space-between", cursor: "pointer", background }}
      onClick={handleClick}
      onMouseEnter={() => setBackground("#dcdcdc")}
      onMouseLeave={() => setBackground("")}
    >
      <TableCell sx={{ width: 0, whiteSpace: "nowrap" }}>
        <Typography>{study.nctNumber}</Typography>
      </TableCell>
      <TableCell
        sx={{ width: 150, textAlign: "center" }}
        onClick={(e) => e.stopPropagation()}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <a href={formattedURL} target="_blank" rel="noreferrer">
            <img
              src={sponsor.logo}
              alt=""
              style={{ maxHeight: 60, maxWidth: "100%" }}
            />
          </a>
        )}
      </TableCell>
      <TableCell>
        <Typography sx={{ width: 400 }}>{study.name}</Typography>
      </TableCell>
      <TableCell>
        {study.description.length > 100 ? (
          <Typography>{study.description.slice(0, 100)}...more</Typography>
        ) : (
          <Typography>{study.description}</Typography>
        )}
      </TableCell>
      <TableCell sx={{ width: 0, whiteSpace: "nowrap" }}>
        <Typography>
          {study.contact.name.last}, {study.contact.name.first}
        </Typography>
        <Typography>{study.contact.email}</Typography>
        <Typography>{study.contact.phoneNumber}</Typography>
      </TableCell>
      <TableCell align="center" sx={{ width: 0, whiteSpace: "nowrap" }}>
        <Typography>{cancer}</Typography>
      </TableCell>
      <TableCell align="center" sx={{ width: 0, whiteSpace: "nowrap" }}>
        <Typography>
          {study.estimatedCloseDate.toDate().toLocaleDateString("en-GB", {
            month: "short",
            year: "numeric",
          })}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default Study;
