import { useState } from "react";
import { useNavigate } from "react-router";

import ContactModal from "./ContactModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Collapse,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

const SideNavMenu = () => {
  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [subHeaderOpen, setSubHeaderOpen] = useState(false);

  const navigate = useNavigate();

  const handleExpandAbout = (event) => {
    event.stopPropagation();
    setSubHeaderOpen((prev) => !prev);
  };

  return (
    <div>
      <IconButton
        color="inherit"
        onClick={() => setOpen(true)}
        edge="start"
        sx={{ mr: 2, ...(open && { display: "none" }) }}
      >
        <MenuIcon sx={{ fontSize: "2em" }} color="primary" />
      </IconButton>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ sx: { background: "#dcdcdc" } }}
      >
        <Box
          sx={{ width: 350, position: "relative", height: "100vh" }}
          role="presentation"
          onClick={() => setOpen(false)}
          onKeyDown={() => setOpen(false)}
        >
          <List>
            <ListItem button onClick={handleExpandAbout}>
              <ListItemText
                primary={
                  <Typography color="#006ba8" fontWeight="bold">
                    About TheranosticTrials
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <Collapse in={subHeaderOpen} timeout="auto" unmountOnExit>
              <List disablePadding>
                <ListItem
                  button
                  onClick={() => navigate("/about/patient")}
                  sx={{ pl: 4 }}
                >
                  <ListItemText
                    primary={
                      <Typography color="#006ba8" fontWeight="bold">
                        Patient
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider />
                <ListItem
                  button
                  onClick={() => navigate("/about/physician")}
                  sx={{ pl: 4 }}
                >
                  <ListItemText
                    primary={
                      <Typography color="#006ba8" fontWeight="bold">
                        Physician
                      </Typography>
                    }
                  />
                </ListItem>
                <Divider />
                <ListItem
                  button
                  onClick={() => navigate("/about/pharma")}
                  sx={{ pl: 4 }}
                >
                  <ListItemText
                    primary={
                      <Typography color="#006ba8" fontWeight="bold">
                        Pharmaceutical
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Collapse>
            <Divider />
            <ListItem button onClick={() => setModalOpen(true)}>
              <ListItemText
                primary={
                  <Typography color="#006ba8" fontWeight="bold">
                    Contact XCancer®
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem
              button
              onClick={() => window.open("https://xcancerstore.com/", "_blank")}
            >
              <ListItemText
                primary={
                  <Typography color="#006ba8" fontWeight="bold">
                    XCancer® Store
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
          </List>

          <List sx={{ position: "absolute", bottom: 0, width: "100%" }}>
            <Divider />
            <ListItem button onClick={() => navigate("/cancers/new")}>
              <ListItemIcon>
                <FontAwesomeIcon icon="cog" style={{ marginRight: "5px" }} />
              </ListItemIcon>
              <ListItemText primary={"Add Cancer"} />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => navigate("/studies/new")}>
              <ListItemIcon>
                <FontAwesomeIcon icon="cog" style={{ marginRight: "5px" }} />
              </ListItemIcon>
              <ListItemText primary={"Add Study"} />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => navigate("/sponsors/new")}>
              <ListItemIcon>
                <FontAwesomeIcon icon="cog" style={{ marginRight: "5px" }} />
              </ListItemIcon>
              <ListItemText primary={"Add Sponsor"} />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => navigate("/stats")}>
              <ListItemIcon>
                <FontAwesomeIcon icon="cog" style={{ marginRight: "5px" }} />
              </ListItemIcon>
              <ListItemText primary={"Stats"} />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => navigate("/updateStudies")}>
              <ListItemIcon>
                <FontAwesomeIcon icon="cog" style={{ marginRight: "5px" }} />
              </ListItemIcon>
              <ListItemText primary={"Update Studies"} />
            </ListItem>
            <Divider />
            <ListItem button>
              <ListItemText
                primary={
                  <Typography color="#006ba8" fontWeight="bold">
                    Terms and Conditions
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem button>
              <ListItemText
                primary={
                  <Typography color="#006ba8" fontWeight="bold">
                    Privacy Policy
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <ContactModal open={modalOpen} setOpen={setModalOpen} />
    </div>
  );
};

export default SideNavMenu;
