import { useParams, useNavigate, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SponsorContact from "./SponsorContact";
import Locations from "./Locations";
import Description from "./Description";
import Publications from "./Publications";
import Documents from "./Documents";
import Ads from "./Ads";

import {
  Container,
  Grid,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  Typography,
  TableContainer,
  IconButton,
} from "@mui/material";

const StudyDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { state: study } = useLocation();

  return (
    <Container maxWidth="xl" sx={{ marginTop: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TableContainer component={Paper} sx={{ borderRadius: 5 }}>
            <Table>
              <TableHead sx={{ backgroundColor: "#006ba8" }}>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Grid container>
                      <Grid item xs={11}>
                        <Typography color="white">{study.name}</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() =>
                            navigate(`/studies/${params.study_id}/edit`)
                          }
                        >
                          <FontAwesomeIcon icon="edit" color="white" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {study && (
                  <>
                    <SponsorContact contact={study.contact} />
                    <Documents
                      eligibility={
                        study.sponsor === "Bayer" ? study.eligibility : null
                      }
                      informationSheet={
                        study.sponsor === "Bayer"
                          ? study.informationSheetLink
                          : null
                      }
                    />

                    <Publications
                      publications={
                        study.sponsor === "Bayer" ? study.publications : []
                      }
                    />
                    <Description synopsis={study.description} />
                    <Locations locations={study.locations} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <Ads
            videoLink={study.videoLink}
            showVideo={study.videoLink && study.sponsor === "Bayer"}
            sponsor={study.sponsor}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default StudyDetails;
