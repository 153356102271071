import { useState } from "react";
import { useImmer } from "use-immer";

import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../firebase";

import {
  Grid,
  Container,
  Paper,
  Button,
  Typography,
  TextField,
  Alert,
} from "@mui/material";

const NewCancer = () => {
  const [state, setState] = useImmer({ name: "" });
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors([]);

    try {
      await setDoc(doc(db, `Cancers`, state.name), {
        subGroups: ["Metastatic", "Localized"],
      });
      setSuccess(true);
      setErrors([]);
      setState((draft) => {
        draft.name = "";
      });
    } catch (e) {
      setSuccess(false);
      setErrors([...errors, e.message]);
    }
  };

  const handleCancerChange = ({ target }) => {
    const { name, value } = target;

    setState((draft) => {
      draft[name] = value;
    });
  };

  //Add ability to upload files
  return (
    <>
      {errors.map((error, index) => (
        <Alert severity="error" key={index}>
          {error}
        </Alert>
      ))}
      {success && <Alert severity="success">Cancer added successfully</Alert>}
      <Container maxWidth="md" sx={{ marginTop: 4 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4} direction="column">
            <Grid item>
              <Paper sx={{ padding: 5 }} elevation={5}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant="h4">Cancer</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      name="name"
                      value={state.name}
                      onChange={handleCancerChange}
                      label="Cancer"
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item>
              <Button type="submit" fullWidth variant="contained">
                <Typography>Submit</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default NewCancer;
