import { useNavigate } from "react-router-dom";

import optionOne from "../../images/about/option-1.png";
import optionTwo from "../../images/about/option-2.jpg";
import optionThree from "../../images/about/option-3.jpg";

import {
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
} from "@mui/material";

const Patient = () => {
  const navigate = useNavigate();
  return (
    <Container sx={{ marginTop: 4 }}>
      <Grid container direction="column" spacing={8}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="h1" color="#006ba8">
            About TheranosticTrials
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <b>TheranosticTrials</b>
          </Typography>
          <br />
          <Typography>
            <b>Increasing Trial Participation Minimizing Care Disparities</b>
          </Typography>
          <br />
          <Typography>
            Today the ability for a patient or physician to search available
            clinical trials is limited to <i>clinicaltrials.gov</i> which can be
            intimidating, overwhelming and provides no ability to educate on the
            science of the trial or therapy. This often leaves the patient and
            referral physicians lost.
          </Typography>
          <br />
          <Typography>
            TheranosticTrials strives to change this experience.
            TheranosticTrials is a novel cancer-specific clinical trials search
            platform that provides an easy to search databank of relevant trials
            with the most advanced educational tools to provide patients and
            physicians the clarity necessary when choosing a clinical trial.
            Through increased awareness and understandable video and written
            education, TheranosticTrials aims to minimize clinical trials
            disparities and equal the treatment playing field for all cancer
            patients.
          </Typography>
          <br />
          <Typography>
            TheranosticTrials is the product of the minds behind XCancer®, a
            clinical trials network of community cancer centers that has
            successfully expanded access and enrollment in clinical trials in
            communities across the U.S.
          </Typography>
          <Typography>
            TheranosticTrials is currently focused on developing this platform
            for Radiopharmaceutical & Molecular-Imaging clinical trials with
            plans to rapidly expand into clinical trials for all cancer
            therapies.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-around">
            <Grid item>
              <CardActionArea>
                <Card raised sx={{ width: 350, height: 350 }}>
                  <CardMedia
                    component="img"
                    height={250}
                    image={optionOne}
                    alt="image"
                  />
                  <CardContent>
                    <Typography variant="h5">
                      Clinical Trials: Myths and Misconceptions
                    </Typography>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
            <Grid item>
              <CardActionArea>
                <Card raised sx={{ width: 350, height: 350 }}>
                  <CardMedia
                    component="img"
                    height={250}
                    image={optionTwo}
                    alt="image"
                  />
                  <CardContent>
                    <Typography variant="h5">
                      Radiopharmaceuticals FAQ
                    </Typography>
                    <br />
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
            <Grid item>
              <CardActionArea
                onClick={() => navigate("/about/health_disparities")}
              >
                <Card raised sx={{ width: 350, height: 350 }}>
                  <CardMedia
                    component="img"
                    height={250}
                    image={optionThree}
                    alt="image"
                  />
                  <CardContent>
                    <Typography variant="h5">
                      Addressing Health Disparities Through Clinical Trials
                    </Typography>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Patient;
