import { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  TableCell,
  TableRow,
  TableBody,
  Typography,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const Study = ({ study }) => {
  const navigate = useNavigate();

  const [background, setBackground] = useState("");

  const handleClick = () =>
    navigate(`/sponsors/${study.sponsor}/studies/${study.nctNumber}/edit`);

  return (
    <TableBody>
      <TableRow
        sx={{ justifyContent: "space-between", cursor: "pointer", background }}
        onClick={handleClick}
        onMouseEnter={() => setBackground("#dcdcdc")}
        onMouseLeave={() => setBackground("")}
      >
        <TableCell sx={{ width: 0, whiteSpace: "nowrap" }}>
          <Typography>{study.nctNumber}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{study.name}</Typography>
        </TableCell>
        <TableCell>
          <IconButton aria-label="delete">
            <DeleteIcon color="error" />
          </IconButton>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default Study;
