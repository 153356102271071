import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { collection } from "firebase/firestore";
import { db } from "../../firebase";

import {
  Typography,
  Grid,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";

const Search = () => {
  const [studies, loading, error] = useCollectionData(
    collection(db, "Studies")
  );
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  const getCancersFromStudies = (studies) => {
    const cancers = [...new Set(studies.map((study) => study.cancers).flat())];

    return cancers.sort((a, b) => a.localeCompare(b));
  };

  useEffect(() => {
    if (!loading) setOptions(getCancersFromStudies(studies));
  }, [loading, studies]);

  const handleCancerChange = (cancer) => navigate(`/studies?cancer=${cancer}`);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} textAlign="center">
        <Typography variant="h4" color="#232264">
          Search Clinical Trials
        </Typography>
      </Grid>
      <Grid
        item
        sx={{ display: "flex", justifyContent: "center", width: "100%" }}
      >
        <Autocomplete
          sx={{ width: "30vw" }}
          options={options}
          loading={loading}
          onChange={(e) => handleCancerChange(e.target.innerText)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Cancer Type"
              id="input"
              error={error}
              helperText={error}
              InputProps={{
                ...params.InputProps,
                sx: { borderRadius: 10 },
                endAdornment: (
                  <>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default Search;
