import { Grid, TextField, Paper, Typography, Button } from "@mui/material";

const Publications = ({ setState, publications }) => {
  const addPublicationField = () => {
    setState((draft) => {
      draft.publications.push({ link: "" });
    });
  };

  return (
    <Paper sx={{ padding: 5 }} elevation={5}>
      <Typography variant="h4" sx={{ marginBottom: 4 }}>
        Publications
      </Typography>
      <Grid container spacing={4}>
        {publications.map((publication, index) => (
          <Grid item xs={12} key={index}>
            <TextField
              fullWidth
              value={publication.link}
              onChange={(e) =>
                setState((draft) => {
                  draft.publications[index].link = e.target.value;
                })
              }
              label="Link"
            />
          </Grid>
        ))}
      </Grid>
      <Button
        onClick={addPublicationField}
        variant="contained"
        sx={{ marginTop: 4 }}
      >
        Add Another Publication
      </Button>
    </Paper>
  );
};

export default Publications;
