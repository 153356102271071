import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { collection } from "firebase/firestore";
import { db } from "../../firebase";

import { Select, MenuItem, Grid, Typography } from "@mui/material";

const getCancersFromStudies = (studies) => {
  const cancers = [...new Set(studies.map((study) => study.cancers).flat())];

  return cancers.sort((a, b) => a.localeCompare(b));
};

const CancerDropdownList = ({ changeCancer }) => {
  const [studies, loading] = useCollectionData(collection(db, "Studies"));
  const [searchParams, setSearchParams] = useSearchParams();
  const cancerName = searchParams.get("cancer");

  const [options, setOptions] = useState();

  const handleCancerChange = (e) => {
    changeCancer({ cancer: e.target.value });
    setSearchParams({ cancer: e.target.value });
  };

  useEffect(() => {
    if (!loading) setOptions(getCancersFromStudies(studies));
  }, [loading, studies]);

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        {options && (
          <Select
            variant="outlined"
            value={cancerName}
            onChange={handleCancerChange}
            sx={{
              borderRadius: 10,
              height: 40,
              textAlign: "center",
              width: "30vw",
            }}
          >
            {options.map((option, index) => (
              <MenuItem value={option} key={index}>
                <Typography fontSize={20}>{option}</Typography>
              </MenuItem>
            ))}
          </Select>
        )}
      </Grid>
    </Grid>
  );
};

export default CancerDropdownList;
