import { AppBar, Toolbar, Typography } from "@mui/material";

const Footer = () => {
  return (
    <>
      <Toolbar />
      <AppBar
        position="fixed"
        sx={{
          bottom: 0,
          top: "auto",
          alignItems: "center",
          justifyContent: "center",
          height: 30,
          background: "#dcdcdc",
        }}
      >
        <Toolbar>
          <Typography color="primary">
            © 2021 XCancer Software LLC | Contact XCancer: 402-991-8468
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Footer;
