import { TableRow, TableCell, Typography, Link } from "@mui/material";

const Documents = ({ eligibility, informationSheet }) => {
  return (
    <>
      <TableRow>
        <TableCell
          sx={{
            width: 0,
            whiteSpace: "nowrap",
            verticalAlign: "top",
          }}
        >
          <Typography>Key Eligibility</Typography>
        </TableCell>
        <TableCell>
          {eligibility ? (
            eligibility !== "" ? (
              <ul style={{ paddingLeft: 15 }}>
                {eligibility.split("~").map((item, index) => (
                  <li key={index}>
                    <Typography>{item}</Typography>
                  </li>
                ))}
              </ul>
            ) : (
              <Typography color="error">(Free Text or Link)</Typography>
            )
          ) : (
            <Typography>Contact Sponsor</Typography>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{
            width: 0,
            whiteSpace: "nowrap",
            verticalAlign: "top",
          }}
        >
          <Typography>Patient Education</Typography>
        </TableCell>
        <TableCell>
          {informationSheet ? (
            informationSheet !== "" ? (
              <Link href={informationSheet} target="_blank" rel="noreferrer">
                <Typography>Information Sheet</Typography>
              </Link>
            ) : (
              <Typography>Information Sheet</Typography>
            )
          ) : (
            <Typography>Contact Sponsor</Typography>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default Documents;
