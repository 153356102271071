import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCXTbKx8-Ylc4QM9EUd-pyywa-gffTOiUg",
  authDomain: "xcancer-trials-837ce.firebaseapp.com",
  projectId: "xcancer-trials-837ce",
  storageBucket: "xcancer-trials-837ce.appspot.com",
  messagingSenderId: "908539596891",
  appId: "1:908539596891:web:b31283d2f8476329de6f7e",
  measurementId: "G-1C7PNT916G",
};

initializeApp(firebaseConfig);

export const db = getFirestore();
export const storage = getStorage();
export const auth = getAuth();
