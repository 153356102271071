import { useState } from "react";
import { useImmer } from "use-immer";

import { doc, setDoc } from "firebase/firestore";
import { db } from "../../../../firebase";

import Study from "./Study";
import StudySponsorContact from "./StudySponsorContact";
import Publications from "./Publications";
import Locations from "./Locations";
import EduMaterial from "./EduMaterial";

import { Grid, Container, Button, Typography, Alert } from "@mui/material";
import EligibilityCriteria from "./EligibilityCriteria";

const NewStudy = () => {
  const [errors, setErrors] = useState([]);
  const [success, setSuccess] = useState(false);

  const [state, setState] = useImmer({
    cancerOptions: [],
    sponsorOptions: [],
    cancers: [],
    subGroups: [],
    nctNumber: "",
    name: "",
    sponsor: "",
    description: "",
    videoLink: "",
    informationSheetLink: "",
    closeDate: new Date(),
    contact: {
      name: {
        first: "",
        last: "",
      },
      title: "",
      email: "",
      phoneNumber: "",
    },
    locations: [
      {
        name: "",
        contact: {
          name: {
            first: "",
            last: "",
          },
          email: "",
          phoneNumber: "",
        },
        address: {
          country: "",
          state: "",
          city: "",
        },
      },
    ],
    publications: [{ link: "" }],
    eligibility: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    const studyData = {
      nctNumber: state.nctNumber.trim(),
      name: state.name || "",
      cancers: state.cancers || [],
      sponsor: state.sponsor || "",
      description: state.description || "",
      videoLink: state.videoLink || "",
      informationSheetLink: state.informationSheetLink || "",
      estimatedCloseDate: state.closeDate,
      contact: {
        name: {
          first: state.contact.name.first || "",
          last: state.contact.name.last || "",
        },
        email: state.contact.email || "",
        phoneNumber: state.contact.phoneNumber || "",
      },
      locations: state.locations || [],
      subGroups: state.subGroups,
      publications: state.publications,
      eligibility: state.eligibility,
    };

    try {
      await setDoc(doc(db, "Studies", state.nctNumber), studyData);
      setErrors([]);
      setSuccess(true);
    } catch (e) {
      setErrors([...errors, e.message]);
    }
  };

  const handleChange = ({ target }) => {
    const name = target.name;
    const value = target.value;

    setState((draft) => {
      draft[name] = value;
    });
  };

  //Add ability to upload files
  return (
    <>
      {errors.map((error, index) => (
        <Alert severity="error" key={index}>
          {error}
        </Alert>
      ))}
      {success && <Alert severity="success">Study uploaded successfully</Alert>}
      <Container maxWidth="md" sx={{ marginTop: 4 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4} direction="column">
            <Grid item>
              <Typography variant="h3" textAlign="center">
                New Study
              </Typography>
            </Grid>
            <Grid item>
              <Study
                state={state}
                setState={setState}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item>
              <Publications
                publications={state.publications}
                setState={setState}
              />
            </Grid>
            <Grid item>
              <StudySponsorContact state={state} setState={setState} />
            </Grid>
            <Grid item>
              <Locations setState={setState} locations={state.locations} />
            </Grid>
            <Grid item>
              <EduMaterial
                video={state.videoLink}
                infoSheet={state.informationSheetLink}
                eligibility={state.eligibility}
                setState={setState}
              />
            </Grid>
            <Grid item>
              <EligibilityCriteria
                eligibility={state.eligibility}
                setState={setState}
              />
            </Grid>
            <Grid item>
              <Button type="submit" fullWidth variant="contained">
                <Typography>Submit</Typography>
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default NewStudy;
