import { useState } from "react";

import { Typography } from "@mui/material";

const Location = ({ location }) => {
  const [showContact, setShowContact] = useState(false);
  return (
    <div
      onClick={() => setShowContact(!showContact)}
      style={{ cursor: "pointer" }}
    >
      <Typography>{location.name}</Typography>
      <Typography>
        {location.address.city}, {location.address.state},{" "}
        {location.address.country}
      </Typography>
      {showContact ? (
        <>
          <Typography>
            {location.contact.name.last}, {location.contact.name.first}
          </Typography>
          <Typography>{location.contact.email}</Typography>
          <Typography>{location.contact.phoneNumber}</Typography>
        </>
      ) : (
        <Typography>...show contact</Typography>
      )}
    </div>
  );
};

export default Location;
