import { Paper, Typography, Grid, TextField } from "@mui/material";

const EduMaterial = ({ setState, video, infoSheet }) => {
  return (
    <Paper sx={{ padding: 5 }} elevation={5}>
      <Typography variant="h4" sx={{ marginBottom: 4 }}>
        Educational Material
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="video"
            value={video}
            onChange={(e) =>
              setState((draft) => {
                draft.videoLink = e.target.value;
              })
            }
            label="Video Link"
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            name="informationSheetLink"
            value={infoSheet}
            onChange={(e) =>
              setState((draft) => {
                draft.informationSheetLink = e.target.value;
              })
            }
            label="Information Sheet Link"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default EduMaterial;
