import {
  Typography,
  Paper,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Container,
} from "@mui/material";

const Survey = () => {
  return (
    <Container sx={{ marginTop: 4 }} maxWidth="md">
      <form>
        <Paper sx={{ padding: 5 }} elevation={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>
                What is the likelihood that you consider a Clinical Trial as a
                Treatment Option for your Cancer?
              </Typography>
              <RadioGroup row name="row-radio-buttons-group">
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="1-Not Likely"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="2-Likely"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Have you participated in a Clinical Trial for your Cancer in the
                Past?
              </Typography>
              <RadioGroup row name="row-radio-buttons-group">
                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                <FormControlLabel value="0" control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <Typography>What best describes you?</Typography>
              <RadioGroup row name="row-radio-buttons-group">
                <FormControlLabel
                  value="patient"
                  control={<Radio />}
                  label="Patient"
                />
                <FormControlLabel
                  value="careGiver"
                  control={<Radio />}
                  label="Care Giver"
                />
                <FormControlLabel
                  value="physician"
                  control={<Radio />}
                  label="Physician"
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label="Other"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Gender
                </FormLabel>
                <RadioGroup row name="row-radio-buttons-group">
                  <FormControlLabel value="m" control={<Radio />} label="M" />
                  <FormControlLabel value="f" control={<Radio />} label="F" />
                  <FormControlLabel
                    value="o"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Age
                </FormLabel>
                <RadioGroup row name="row-radio-buttons-group">
                  <FormControlLabel value="0" control={<Radio />} label="<18" />
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="18-54"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="55-74"
                  />
                  <FormControlLabel value="3" control={<Radio />} label=">74" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Race
                </FormLabel>
                <RadioGroup row name="row-radio-buttons-group">
                  <FormControlLabel
                    value="Black/African American"
                    control={<Radio />}
                    label="Black/African American"
                  />
                  <FormControlLabel
                    value="White"
                    control={<Radio />}
                    label="White"
                  />
                  <FormControlLabel
                    value="Native American/Native Alaskan"
                    control={<Radio />}
                    label="Native American/Native Alaskan"
                  />
                  <FormControlLabel
                    value="Asian"
                    control={<Radio />}
                    label="Asian"
                  />
                  <FormControlLabel
                    value="Pacific Islander"
                    control={<Radio />}
                    label="Pacific Islander"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Residence
                </FormLabel>
                <RadioGroup row name="row-radio-buttons-group">
                  <FormControlLabel
                    value="Urban"
                    control={<Radio />}
                    label="Urban"
                  />
                  <FormControlLabel
                    value="Suburban"
                    control={<Radio />}
                    label="Suburban"
                  />
                  <FormControlLabel
                    value="Rural"
                    control={<Radio />}
                    label="Rural"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Household Income
                </FormLabel>
                <RadioGroup row name="row-radio-buttons-group">
                  <FormControlLabel
                    value="<15,000"
                    control={<Radio />}
                    label="<15,000"
                  />
                  <FormControlLabel
                    value="15-24,999"
                    control={<Radio />}
                    label="15-24,999"
                  />
                  <FormControlLabel
                    value="25-34,999"
                    control={<Radio />}
                    label="25-34,999"
                  />
                  <FormControlLabel
                    value="35-49,999"
                    control={<Radio />}
                    label="35-49,999"
                  />
                  <FormControlLabel
                    value="50-74,999"
                    control={<Radio />}
                    label="50-74,999"
                  />
                  <FormControlLabel
                    value="75-99,999"
                    control={<Radio />}
                    label="75-99,999"
                  />
                  <FormControlLabel
                    value="100-200,000"
                    control={<Radio />}
                    label="100-200,000"
                  />
                  <FormControlLabel
                    value=">200,000"
                    control={<Radio />}
                    label=">200,000"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Highest Level of Education Completed
                </FormLabel>
                <RadioGroup row name="row-radio-buttons-group">
                  <FormControlLabel
                    value="Did Not Complete High School/GED"
                    control={<Radio />}
                    label="Did Not Complete High School/GED"
                  />
                  <FormControlLabel
                    value="High School/GED"
                    control={<Radio />}
                    label="High School/GED"
                  />
                  <FormControlLabel
                    value="College"
                    control={<Radio />}
                    label="College"
                  />
                  <FormControlLabel
                    value="Post Graduate"
                    control={<Radio />}
                    label="Post Graduate"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Insurance Coverage
                </FormLabel>
                <RadioGroup row name="row-radio-buttons-group">
                  <FormControlLabel
                    value="No Insurance"
                    control={<Radio />}
                    label="No Insurance"
                  />
                  <FormControlLabel
                    value="Medicaid"
                    control={<Radio />}
                    label="Medicaid"
                  />
                  <FormControlLabel
                    value="Medicare"
                    control={<Radio />}
                    label="Medicare"
                  />
                  <FormControlLabel
                    value="Private/Employer Insurance"
                    control={<Radio />}
                    label="Private/Employer Insurance"
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio />}
                    label="Other"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Internet Access
                </FormLabel>
                <RadioGroup row name="row-radio-buttons-group">
                  <FormControlLabel
                    value="No Internet Access"
                    control={<Radio />}
                    label="No Internet Access"
                  />
                  <FormControlLabel
                    value="Use of Internet is Infrequent"
                    control={<Radio />}
                    label="Use of Internet is Infrequent"
                  />
                  <FormControlLabel
                    value="Commonly Use Internet"
                    control={<Radio />}
                    label="Commonly Use Internet"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Button variant="contained">Submit</Button>
            </Grid>
          </Grid>
        </Paper>
      </form>
    </Container>
  );
};

export default Survey;
