import { useImmer } from "use-immer";

import { Typography, Modal, Paper, Grid, IconButton } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

const RateModal = ({ open, setOpen }) => {
  const [stars, setStars] = useImmer([
    { color: "#006ba8" },
    { color: "#006ba8" },
    { color: "#006ba8" },
    { color: "#006ba8" },
    { color: "#006ba8" },
  ]);

  const handleClose = () => setOpen(false);

  const starHover = (maxIndex, color) => {
    for (let i = 0; i <= maxIndex; i++) {
      setStars((draft) => {
        draft[i].color = color;
      });
    }
  };

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: 5,
            width: 500,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography variant="h5">
                How beneficial was this video in helping you to consider a
                clinical trial?
              </Typography>
              <Typography>
                {stars.map((star, index) => (
                  <IconButton
                    key={index}
                    onMouseEnter={() => starHover(index, "#ffa500")}
                    onMouseLeave={() => starHover(index, "#006ba8")}
                  >
                    <StarIcon sx={{ fontSize: 36, color: star.color }} />
                  </IconButton>
                ))}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
};

export default RateModal;
