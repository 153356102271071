import { useParams } from "react-router-dom";

import { TableContainer, Paper, Container, Typography } from "@mui/material";
import StudiesContainer from "./StudiesContainer";

const SponsorStudies = () => {
  const { sponsor } = useParams();

  return (
    <Container sx={{ marginTop: 4, textAlign: "center" }}>
      <Typography variant="h2" color="#006ba8">
        Your Studies
      </Typography>
      <TableContainer component={Paper}>
        <StudiesContainer sponsor={sponsor} />
      </TableContainer>
    </Container>
  );
};

export default SponsorStudies;
