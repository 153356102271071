import { Grid, Container, Typography } from "@mui/material";

const Physician = () => {
  return (
    <Container sx={{ marginTop: 4 }}>
      <Grid container direction="column" spacing={8}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="h1" color="#006ba8">
            About TheranosticTrials
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            TheranosticTrials brings together all Radiopharmaceutical and
            Molecular Imaging Trials for ALL cancer types. This allows for
            efficient review and education of these novel cancer treatments. In
            addition, this site contains links to the supporting peer-reviewed
            literature, participating trial sites, contacts, and basic
            inclusion/exclusion criteria.
          </Typography>
          <br />
          <Typography>
            If you are a physician interested in participating in these clinical
            trials, please contact the sponsors listed or contact XCancer®
            directly. XCancer® works with community cancer centers across the
            U.S. with extensive to no prior clinical trial experience.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Physician;
