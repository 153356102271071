import { TableRow, TableCell, Typography } from "@mui/material";

const SponsorContact = ({ contact }) => {
  return (
    <TableRow>
      <TableCell
        sx={{
          width: 0,
          whiteSpace: "nowrap",
          verticalAlign: "top",
        }}
      >
        <Typography>Sponsor Contact</Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {contact.name.last}, {contact.name.first}{" "}
          {contact.title && `(${contact.title})`}
        </Typography>
        <Typography>{contact.email}</Typography>
        <Typography>{contact.phoneNumber}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default SponsorContact;
