import { useCollection } from "react-firebase-hooks/firestore";

import { collection } from "firebase/firestore";
import { db } from "../../firebase";

const Cancers = () => {
  const [value, loading, error] = useCollection(collection(db, "Cancers"));

  if (loading) return <h1>Loading...</h1>;
  if (error) return <h1>{error}</h1>;

  return (
    <ul>
      {value.docs.map((doc) => (
        <li key={doc.id}>
          <h2>{doc.id}</h2>
          {doc.data().subGroups.map((subGroup, index) => (
            <h3 key={index}>{subGroup}</h3>
          ))}
        </li>
      ))}
    </ul>
  );
};

export default Cancers;
