import { useNavigate } from "react-router-dom";

import image from "../../images/health_disparities_banner.jpg";

import { Container, Typography, Button } from "@mui/material";

const HealthDisparities = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          height: 230,
          width: "100%",
          zIndex: 2,
          fontWeight: "bold",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h1" color="#232264">
          Health Disparities
        </Typography>
      </div>
      <img
        src={image}
        alt="Banner"
        height={230}
        overflow="hidden"
        width="100%"
        style={{
          objectFit: "cover",
          objectPosition: "20% 80%",
          opacity: "70%",
        }}
      />
      <Container maxWidth="xl">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            sx={{
              background: "grey",
              padding: 5,

              width: "100%",
            }}
          >
            Sponsor Ad Here
          </Typography>
        </div>
      </Container>
      <Container sx={{ marginTop: 4 }}>
        <Typography>
          <b>Health Disparities</b> continue to plague the U.S. healthcare
          system. Because of these variables not all patients receive the same
          healthcare which often results in worse outcomes. Key factors that
          result in disparities include: race, age, gender, income, educational
          level, access to healthcare/insurance, and access to the internet.
        </Typography>
        <br />
        <Typography>
          <b style={{ color: "#006ba8" }}>TheranosticTrials</b> is taking active
          steps to help equal the playing field for all cancer patients.
          Education around Clinical Trials in general, education regarding new
          investigational treatments, and expanding access to Clinical Trials
          are a key component of solving healthcare disparities when it comes to
          treating cancer. Our scientific knowledge of how cancers develop,
          expand, spread and evade our bodies defenses and most of our current
          standard of care therapies is rapidly expanding through thousands of
          clinical trials. Unfortunately, only 3-5% of cancer patients in the
          U.S. ever participate in these new potentially life-saving therapies.
          <a href="/">There are several Myths and Misconceptions</a> regarding
          Cancer Research and Clinical Trials that unfortunately deter many
          cancer patients from considering enrollment on a Trial. Many patients
          and cultures have a strong distrust of the medical and research
          community. Truth be told, today most patients who enroll on clinical
          trials and gain access to some of the most promising new cancer
          therapies, also have the most access to healthcare, higher education
          status, resources to travel, and are fluent with the internet.
          Clinical Trials have many advantages, one very important one being the
          reduction of financial strain on patients. Currently the #1 cause of
          bankruptcy in the U.S. is Medical Care. Many of the cancer therapies
          and imaging tests required to treat a cancer are covered at no cost to
          the patient while on a Trial providing huge cost savings to patients
          and society.
        </Typography>
        <br />
        <Typography>
          <b style={{ color: "#006ba8" }}>TheranosticTrials</b> is working with
          major pharmaceutical companies to gather real world data from people
          like you regarding your thoughts on cancer Clinical Trials and
          non-identifiable characteristics about you to better learn how to
          minimize disparities among patients and improve cancer outcomes for
          all patients.
        </Typography>
        <Typography gutterBottom>
          YOU CAN DO YOUR PART TO HELP EQUAL THE PLAYING FIELD AND PUT A DENT IN
          DISPARITIES
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={() => navigate("/about/health_disparities/survey")}
          >
            Take Survey
          </Button>
        </div>
      </Container>
    </>
  );
};

export default HealthDisparities;
