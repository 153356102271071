import { TableRow, TableCell, Typography, Link } from "@mui/material";

const Publications = ({ publications }) => {
  return (
    <TableRow>
      <TableCell
        sx={{
          width: 0,
          whiteSpace: "nowrap",
          verticalAlign: "top",
        }}
      >
        <Typography>Publications</Typography>
      </TableCell>
      <TableCell>
        {publications.length === 0 ? (
          <Typography>Contact Sponsor</Typography>
        ) : (
          publications.map((pub, index) => (
            <Link key={index} href={pub.link} target="_blank" rel="noreferrer">
              <Typography>Publication {index + 1}</Typography>
            </Link>
          ))
        )}
      </TableCell>
    </TableRow>
  );
};

export default Publications;
