import Location from "./Location";

import { TableRow, TableCell, Typography } from "@mui/material";

const Locations = ({ locations }) => {
  return (
    <TableRow>
      <TableCell
        sx={{
          width: 0,
          whiteSpace: "nowrap",
          verticalAlign: "top",
        }}
      >
        <Typography>Locations</Typography>
      </TableCell>
      <TableCell>
        {locations?.map((location, index) => (
          <div key={index}>
            <Location location={location} />
            {locations.length > 1 && <br />}
          </div>
        ))}
      </TableCell>
    </TableRow>
  );
};

export default Locations;
