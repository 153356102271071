import { Grid, Container, Typography } from "@mui/material";

const Pharma = () => {
  return (
    <Container sx={{ marginTop: 4 }}>
      <Grid container direction="column" spacing={8}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="h1" color="#006ba8">
            About TheranosticTrials
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            TheranosticTrials is the First digital platform housing all current
            Radiopharmaceutical and Molecular Imaging Clinical Trials on a
            single site to improve access and knowledge of these novel therapies
            for cancer patients and their physicians. There are 2 levels of
            visibility for sponsors and their trials on this site:
          </Typography>
          <ul>
            <li>
              <Typography>
                Level 1: Content for each trial will contain basic,
                non-modifiable content including a trial description, study
                contact, and locations.
              </Typography>
            </li>
            <li>
              <Typography>
                Level 2: Content will include content from Level 1 in addition
                Level 2 will allow for a sponsor to add additional educational
                material, publication links, patient centered educational
                videos, disparities data, ad space, and links to home site.
                Please contact XCancer® for additional information.
              </Typography>
            </li>
          </ul>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Pharma;
