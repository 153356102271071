import { Grid, Typography, TextField, Paper } from "@mui/material";

const StudySponsorContact = ({ state, setState }) => {
  return (
    <Paper sx={{ padding: 5 }} elevation={5}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4">Study Sponsor Contact</Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="firstName"
            value={state.contact.name.first}
            onChange={(e) =>
              setState((draft) => {
                draft.contact.name.first = e.target.value;
              })
            }
            label="First Name"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="lastName"
            value={state.contact.name.last}
            onChange={(e) =>
              setState((draft) => {
                draft.contact.name.last = e.target.value;
              })
            }
            label="Last Name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="title"
            value={state.contact.title || ""}
            onChange={(e) =>
              setState((draft) => {
                draft.contact.title = e.target.value;
              })
            }
            label="Title"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="email"
            value={state.contact.email}
            onChange={(e) =>
              setState((draft) => {
                draft.contact.email = e.target.value;
              })
            }
            label="Email"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="phoneNumber"
            value={state.contact.phoneNumber}
            onChange={(e) =>
              setState((draft) => {
                draft.contact.phoneNumber = e.target.value;
              })
            }
            label="Phone Number"
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default StudySponsorContact;
