import { TableRow, TableCell, Typography } from "@mui/material";

const Description = ({ synopsis }) => {
  return (
    <TableRow>
      <TableCell
        sx={{
          width: 0,
          whiteSpace: "nowrap",
          verticalAlign: "top",
        }}
      >
        <Typography>Description</Typography>
      </TableCell>
      <TableCell>
        <Typography>{synopsis}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default Description;
