import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Typography, Modal, Paper, Grid } from "@mui/material";

const ContactModal = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: 5,
            width: 500,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography sx={{ fontSize: 36 }} color="#006ba8">
                Contact XCancer
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <FontAwesomeIcon icon="phone" size="2x" color="#006ba8" />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Hours: Mon-Thur 8am-4:30pm CT, Fri 8am-3pm CT
              </Typography>
              <Typography>Office Phone: 402-991-8468</Typography>
              <Typography>Office Fax: 402-991-8469</Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <FontAwesomeIcon icon="envelope" size="2x" color="#006ba8" />
            </Grid>
            <Grid item xs={12}>
              <Typography>Luke Nordquist, MD, FACP, CEO</Typography>
              <Typography>drnordquist@gucancer.com</Typography>
              <br />
              <Typography>
                Tony Romero, MS, CCRC, Director of Research
              </Typography>
              <Typography>romero@gucancer.com</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
};

export default ContactModal;
