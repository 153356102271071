// import { useContext } from "react";
import { useNavigate } from "react-router-dom";

// import { AuthContext } from "../../contexts/AuthContext";

import {
  Button,
  Container,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

const Login = () => {
  // const { state, dispatch } = useContext(AuthContext);

  const navigate = useNavigate();

  return (
    <Container sx={{ marginTop: 10, width: "30vw" }}>
      <Paper sx={{ padding: 10, borderRadius: 10 }} elevation={5}>
        <Grid container direction="column" spacing={4}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography variant="h2" color="primary">
              Login
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Email" type="email" />
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Password" type="password" />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => navigate("/sponsors/Bayer")}
                >
                  Log In
                </Button>
              </Grid>
              <Grid item>
                <Link href="/signup">Request an account</Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Login;
