import { useNavigate, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Logo from "../../images/xcancer-logo.png";

import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
} from "@mui/material";

const Dashboard = () => {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <Container sx={{ marginTop: 10, height: "100%" }}>
      <Grid container direction="column" spacing={8}>
        <Grid item sx={{ display: "flex", justifyContent: "center" }}>
          <img src={Logo} alt="logo" height="200vh" />
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between">
            <Grid item sx={{ textAlign: "center" }}>
              <CardActionArea
                sx={{ borderRadius: 10 }}
                onClick={() => navigate(`/sponsors/${params.sponsor}/profile`)}
              >
                <Card
                  sx={{ borderRadius: 10, padding: 7, width: 250, height: 250 }}
                  raised
                >
                  <CardMedia>
                    <FontAwesomeIcon icon="user" size="7x" color="#006ba8" />
                  </CardMedia>
                  <CardContent
                    sx={{ margin: 0, paddingTop: 2, paddingLeft: 0 }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ whiteSpace: "nowrap" }}
                      color="primary"
                    >
                      Sponsor Profile
                    </Typography>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
            <Grid item sx={{ textAlign: "center" }}>
              <CardActionArea
                sx={{ borderRadius: 10 }}
                onClick={() => navigate(`/studies/new`)}
              >
                <Card
                  sx={{ borderRadius: 10, padding: 7, width: 250, height: 250 }}
                  raised
                >
                  <CardMedia>
                    <FontAwesomeIcon icon="plus" size="7x" color="#006ba8" />
                  </CardMedia>
                  <CardContent
                    sx={{ margin: 0, paddingTop: 2, paddingLeft: 0 }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ whiteSpace: "nowrap" }}
                      color="primary"
                    >
                      Add Study
                    </Typography>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
            <Grid item sx={{ textAlign: "center" }}>
              <CardActionArea
                sx={{ borderRadius: 10 }}
                onClick={() => navigate(`/sponsors/${params.sponsor}/studies`)}
              >
                <Card
                  sx={{ borderRadius: 10, padding: 7, width: 250, height: 250 }}
                  raised
                >
                  <CardMedia>
                    <FontAwesomeIcon
                      icon="clipboard"
                      size="7x"
                      color="#006ba8"
                    />
                  </CardMedia>
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{ whiteSpace: "nowrap" }}
                      color="primary"
                    >
                      Study Portal
                    </Typography>
                  </CardContent>
                </Card>
              </CardActionArea>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
