import { useEffect, useCallback } from "react";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../../firebase";

import {
  Grid,
  Typography,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
} from "@mui/material";
import { DatePicker } from "@mui/lab";

const Study = ({ state, setState, handleChange }) => {
  const getOptions = useCallback(async () => {
    const cancersCollection = await getDocs(collection(db, `Cancers`));
    const sponsorsCollection = await getDocs(collection(db, `Sponsors`));

    const cancerNames = [];
    const sponsorNames = [];

    cancersCollection.forEach((item) => cancerNames.push(item.id));
    sponsorsCollection.forEach((item) => sponsorNames.push(item.id));

    setState((draft) => {
      draft.cancerOptions = cancerNames;
      draft.sponsorOptions = sponsorNames;
    });
  }, [setState]);

  useEffect(() => getOptions(), [getOptions]);

  return (
    <Paper sx={{ padding: 5 }} elevation={5}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h4">Study</Typography>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            required
            options={state.cancerOptions}
            onChange={(e, cancers) => {
              setState((draft) => {
                draft.cancers = cancers;
              });
            }}
            getOptionLabel={(option) => option}
            filterSelectedOptions
            renderInput={(params) => <TextField {...params} label="Cancers" />}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            required
            options={["Metastatic", "Localized"]}
            onChange={(e, subGroup) => {
              setState((draft) => {
                draft.subGroups = subGroup;
              });
            }}
            getOptionLabel={(option) => option}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField {...params} label="Sub Groups" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            name="name"
            value={state.name}
            onChange={handleChange}
            label="Trial Name"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            value={state.nctNumber}
            onChange={handleChange}
            label="NCT #"
            name="nctNumber"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Sponsor</InputLabel>
            <Select
              fullWidth
              required
              variant="outlined"
              label="Sponsor"
              value={state.sponsor}
              onChange={(e) => {
                setState((draft) => {
                  draft.sponsor = e.target.value;
                });
              }}
            >
              {state.sponsorOptions.map((option, index) => (
                <MenuItem value={option} key={index}>
                  <Typography>{option}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            required
            rows={4}
            name="description"
            value={state.description}
            onChange={handleChange}
            label="Description"
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="Est. Close Date"
            value={state.closeDate}
            views={["year", "month"]}
            onChange={(newValue) =>
              setState((draft) => {
                draft.closeDate = newValue.toDate();
              })
            }
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Study;
