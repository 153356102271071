import Header from "./Header";
import Footer from "./Footer";

import { Grid } from "@mui/material";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Grid container direction="column" justifyContent="space-around">
        <Grid item>{children}</Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default Layout;
