import { useCollectionData } from "react-firebase-hooks/firestore";

import { db } from "../../firebase";
import { collection, query, where } from "firebase/firestore";

import Study from "./Study";

import {
  CircularProgress,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

const StudiesContainer = ({ cancer }) => {
  const [studies, loading, error] = useCollectionData(
    query(collection(db, `Studies`), where("cancers", "array-contains", cancer))
  );

  if (error) {
    console.error(error);
    return null;
  }

  if (loading) {
    return (
      <TableBody>
        <TableRow>
          <TableCell colSpan={8} sx={{ textAlign: "center" }}>
            <CircularProgress sx={{ fontSize: 100 }} />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return (
    <TableBody>
      {studies?.map((study, index) => (
        <Study study={study} cancer={cancer} key={index} />
      ))}
    </TableBody>
  );
};

export default StudiesContainer;
