import { useSearchParams } from "react-router-dom";

import CancerDropdownList from "./CancerDropdownList";
import StudiesContainer from "./StudiesContainer";

import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  Paper,
  Grid,
} from "@mui/material";

const Studies = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const cancer = searchParams.get("cancer");

  return (
    <Grid container direction="column" spacing={4} sx={{ marginTop: 1 }}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{ background: "grey", padding: 5, width: "90%", margin: 0 }}
        >
          Sponsor Ad Here
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ marginLeft: 10, marginRight: 10 }}>
        <Grid container>
          <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
            <Typography>Last Updated: 07/20/22</Typography>
          </Grid>
          <Grid item xs={4}>
            <CancerDropdownList
              cancerName={cancer}
              changeCancer={setSearchParams}
            />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#006ba8" }}>
                <TableCell sx={{ width: 0 }}>
                  <Typography fontSize={20} color="white">
                    NCT #
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: 0 }} align="center">
                  <Typography fontSize={20} color="white">
                    Sponsor
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: 0, whiteSpace: "nowrap" }}>
                  <Typography fontSize={20} color="white">
                    Trial Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontSize={20} color="white">
                    Description
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: 0, whiteSpace: "nowrap" }}>
                  <Typography fontSize={20} color="white">
                    Study Contact
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 0, whiteSpace: "nowrap" }}
                >
                  <Typography fontSize={20} color="white">
                    Cancer Type
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ width: 0, whiteSpace: "nowrap" }}
                >
                  <Typography fontSize={20} color="white">
                    Est. Close Date
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <StudiesContainer cancer={cancer} />
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Studies;
