import { useState, useEffect } from "react";
import { useImmer } from "use-immer";

import {
  collection,
  getDocs,
  limit,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "../../firebase";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";

const CustomCheckbox = ({ label, nctNumber, setStudyOptions }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
    setStudyOptions((draft) => {
      const immerStudy = draft.find(
        (studyFromArray) => studyFromArray.nctNumber === nctNumber
      );

      switch (label) {
        case "Imaging":
          immerStudy.studyType.imaging = !immerStudy.studyType.imaging;
          break;
        case "Treatment":
          immerStudy.studyType.treatment = !immerStudy.studyType.treatment;
          break;
        case "Adult":
          immerStudy.studyPopulation.adult = !immerStudy.studyPopulation.adult;
          break;
        case "Pediatric":
          immerStudy.studyPopulation.pediatric =
            !immerStudy.studyPopulation.pediatric;
          break;
        default:
          break;
      }
    });
  };

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} />}
      label={label}
    />
  );
};

const UpdateStudies = () => {
  const [studiesCollection, loading] = useCollectionData(
    collection(db, `Studies`)
  );

  const [studyOptions, setStudyOptions] = useImmer([]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const asyncFetch = async (study) => {
      const studyQuery = query(
        collection(db, "Studies"),
        where("nctNumber", "==", study.nctNumber),
        limit(1)
      );

      const querySnapshot = await getDocs(studyQuery);
      querySnapshot.forEach((document) => {
        updateDoc(document, {
          type: study.studyType,
          population: study.population,
        });
      });
    };

    asyncFetch(studyOptions[0]);

    studyOptions.forEach((study) => {
      asyncFetch(study);
    });
  };

  useEffect(() => {
    setStudyOptions(() =>
      studiesCollection?.map((study) => ({
        nctNumber: study.nctNumber,
        studyType: {
          imaging: false,
          treatment: false,
        },
        studyPopulation: {
          pediatric: false,
          adult: false,
        },
      }))
    );
  }, [studiesCollection, setStudyOptions]);

  return (
    <form onSubmit={handleSubmit}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 0, whiteSpace: "nowrap" }}>
                <Typography>NCT #'s</Typography>
              </TableCell>
              <TableCell>
                <Typography>Title</Typography>
              </TableCell>
              <TableCell sx={{ width: 0, whiteSpace: "nowrap" }}>
                <Typography>Study Type</Typography>
              </TableCell>
              <TableCell sx={{ width: 0, whiteSpace: "nowrap" }}>
                <Typography>Study Population</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading &&
              studiesCollection.map((study, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      width: 0,
                      whiteSpace: "nowrap",
                      verticalAlign: "top",
                    }}
                  >
                    <Typography>{study.nctNumber}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{study.name}</Typography>
                  </TableCell>
                  <TableCell sx={{ width: 0, whiteSpace: "nowrap" }}>
                    <CustomCheckbox
                      key={index}
                      label="Imaging"
                      nctNumber={study.nctNumber}
                      setStudyOptions={setStudyOptions}
                    />
                    <CustomCheckbox
                      key={index + 1000}
                      label="Treatment"
                      nctNumber={study.nctNumber}
                      setStudyOptions={setStudyOptions}
                    />
                  </TableCell>
                  <TableCell sx={{ width: 0, whiteSpace: "nowrap" }}>
                    <CustomCheckbox
                      key={index + 10000}
                      label="Adult"
                      nctNumber={study.nctNumber}
                      setStudyOptions={setStudyOptions}
                    />
                    <CustomCheckbox
                      key={index + 100000}
                      label="Pediatric"
                      nctNumber={study.nctNumber}
                      setStudyOptions={setStudyOptions}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button type="submit" variant="contained">
        Submit
      </Button>
    </form>
  );
};

export default UpdateStudies;
