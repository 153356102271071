import React, { useState } from "react";
// import ReactPlayer from "react-player/youtube";
import { useNavigate } from "react-router";

import RateModal from "./RateModal";

import { useDownloadURL } from "react-firebase-hooks/storage";
import { storage } from "../../../firebase";
// import { getDoc, doc } from "firebase/firestore";
import { ref } from "firebase/storage";

// import backupAd from "../../../images/clarity_example_ad.png";
// import backupSmallAd from "../../../images/bayer_example_ad_8x9.png";

import { Grid, Button, Typography } from "@mui/material";

// const Video = ({ videoLink, showVideo, ad }) => {
//   const [open, setOpen] = useState(false);
//   if (videoLink && showVideo) {
//     return (
//       <>
//         <Grid item xs={12}>
//           <ReactPlayer controls url={videoLink} width="100%" />
//         </Grid>
//         <Grid item xs={12}>
//           <Button variant="contained" onClick={() => setOpen(true)}>
//             Rate This Video
//           </Button>
//         </Grid>
//         <RateModal open={open} setOpen={setOpen} />
//       </>
//     );
//   }

//   return (
//     <Grid item xs={12}>
//       <Box
//         width="100%"
//         height="360px"
//         sx={{
//           background: "#dcdcdc",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >
//         <img src={ad} alt="Sponsor Ad" height="360px" width="100%" />
//       </Box>
//     </Grid>
//   );
// };

const Ads = ({ videoLink, showVideo, sponsor }) => {
  const navigate = useNavigate();
  // const [ads, setAds] = useState({ main: backupAd, small: backupSmallAd });
  const [disparitiesAd] = useDownloadURL(
    ref(storage, "gs://xcancer-trials-837ce.appspot.com/disparities-ad .png")
  );
  const [open, setOpen] = useState(false);

  // const getData = useCallback(async () => {
  //   try {
  //     const sponsorDocRef = await getDoc(doc(db, "Sponsors", sponsor));
  //     const sponsorDoc = sponsorDocRef.exists() && sponsorDocRef.data();

  //     if (sponsorDoc.ad && sponsorDoc.smallAd) {
  //       setAds({ main: sponsorDoc?.ad, small: sponsorDoc?.smallAd });
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }, [sponsor]);

  // useEffect(() => getData(), [getData]);

  return (
    <Grid container direction="column" spacing={3}>
      <Grid
        item
        xs={12}
        sx={{
          background: "grey",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 3,
          marginLeft: 3,
        }}
      >
        <Typography sx={{ marginTop: 18, marginBottom: 18 }}>
          Sponsor Video Here
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={() => setOpen(true)}>
          Rate This Video
        </Button>
      </Grid>
      <RateModal open={open} setOpen={setOpen} />
      <Grid item xs={12} textAlign="right">
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <img
              src={disparitiesAd || null}
              onClick={() => navigate("/about/health_disparities")}
              style={{ cursor: "pointer" }}
              alt="Sponsor Ad"
              height="360px"
              width="100%"
            />
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                background: "grey",
                paddingTop: 21,
                paddingBottom: 20,
                paddingLeft: 13,
                paddingRight: 15,
              }}
            >
              Sponsor Ad Here
            </Typography>
            {/* <img src={ads.small} alt="Sponsor Ad" height="360px" width="100%" /> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    // <Grid container direction="column" spacing={3}>
    //   <Video
    //     videoLink={videoLink}
    //     showVideo={showVideo}
    //     ads={{ main: ads.main, backup: backupAd }}
    //   />
    //   <Grid item xs={12} textAlign="right">
    //     <Grid container spacing={1}>
    //       <Grid item xs={6}>
    //         <img
    //           src={disparitiesAd || null}
    //           onClick={() => navigate("/about/health_disparities")}
    //           style={{ cursor: "pointer" }}
    //           alt="Sponsor Ad"
    //           height="360px"
    //           width="100%"
    //         />
    //       </Grid>
    //       <Grid item xs={6}>
    //         <img src={ads.small} alt="Sponsor Ad" height="360px" width="100%" />
    //       </Grid>
    //     </Grid>
    //   </Grid>
    // </Grid>
  );
};

export default Ads;
