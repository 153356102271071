import { useState, useEffect, useContext } from "react";
import { useCollection } from "react-firebase-hooks/firestore";

import { collection } from "firebase/firestore";
import { db } from "../../firebase";

import { AuthContext } from "../../contexts/AuthContext";

import {
  Button,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
  Link,
} from "@mui/material";

const Signup = () => {
  const [options, setOptions] = useState([]);

  const {
    state: { user, error },
    dispatch,
  } = useContext(AuthContext);

  const [sponsors, loading] = useCollection(collection(db, "Sponsors"));

  const [sponsor, setSponsor] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (!loading) setOptions(sponsors.docs.map((s) => s.id));
    console.log(user);
  }, [loading, sponsors, user]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    dispatch({
      type: "SIGNUP",
      payload: { sponsor, firstName, lastName, email, password },
    });
  };

  return (
    <Container sx={{ marginTop: 10, minWidth: "30vw", maxWidth: "50vw" }}>
      {user && user.firstName}
      <Paper sx={{ padding: 10, borderRadius: 10 }} elevation={5}>
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" spacing={4}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography variant="h2" color="primary">
                Request an Account
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={options}
                loading={loading}
                onChange={(e) => setSponsor(e.target.innerText)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Company Name"
                    error={error}
                    helperText={error}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    label="First Name"
                    type="firstName"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    required
                    label="Last Name"
                    type="lastName"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Button variant="contained" type="submit">
                    Create Account
                  </Button>
                </Grid>
                <Grid item>
                  <Link href="/login">Already have an account? Log in</Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default Signup;
